(function() {
    var ready;
  
    ready = function() {
      $('.alert').fadeIn().delay(2000).fadeOut();
      $('[data-toggle="popover"]').popover();
      $('[data-toggle="tooltip"]').tooltip();

      if (!$('#employee_mail_only').is(':checked')) $('#mail_type').hide();

      $('#employee_mail_only').on('click', function() {
        $(this).is(':checked') ? $('#mail_type').slideDown() : $('#mail_type').slideUp() && $('#mail_type select').prop("selectedIndex", 0).val();
      });

      var checkBoxes = $("#accounts input[type='checkbox']");
      var allCheckBoxesToggler = $("#user_admin");

      allCheckBoxesToggler.on('click', function() {
        checkBoxes.each(function() {
          console.log(allCheckBoxesToggler[0].checked);
          $(this).prop('checked', allCheckBoxesToggler[0].checked);
        });
      });
    };
  
    //$(document).ready(ready);
    $(document).on('turbolinks:load', ready);
  
}).call(this);